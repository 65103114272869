.spinner-container {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 80px;
    height: 80px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #6f6f6f;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Анимация вращения */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}